import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "w-full text-sm mb-1 font-semibold" }
const _hoisted_3 = { class: "flex flex-row w-full" }
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TailSelect = _resolveComponent("TailSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label) + " : ", 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_TailSelect, {
        modelValue: _ctx.autocompleteValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.autocompleteValue) = $event)),
        options: {
          classNames: 'w-full',
        }
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, idx) => {
            return (_openBlock(), _createElementBlock("option", {
              key: 'autocomplete-option-' + idx,
              value: _ctx.itemValue ? item[_ctx.itemValue] : item.value
            }, [
              _renderSlot(_ctx.$slots, "option", _normalizeProps(_guardReactiveProps(item)), () => [
                _createTextVNode(_toDisplayString(_ctx.itemText ? item[_ctx.itemText] : item.text), 1)
              ])
            ], 8, _hoisted_4))
          }), 128))
        ]),
        _: 3
      }, 8, ["modelValue"])
    ])
  ]))
}
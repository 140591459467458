
import { computed, defineComponent, PropType, watch } from "vue";
import { TextValueType } from "./types";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String as PropType<string | number>,
      default: "",
    },
    items: {
      type: Array as PropType<TextValueType[] | any[]>,
      default: [],
    },
    itemText: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const autocompleteValue = computed({
      get: () => props.value,
      set: (value) => emit("update:value", value),
    });

    return {
      autocompleteValue,
    };
  },
});
